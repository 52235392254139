.entry{
  position:relative;
  padding: 0 3rem 0 1.5rem;
  transition: all 0.15s ease;
  width: 100vw;
  max-width: 30rem;
  margin-right: 2rem;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0 auto;
  .entry__wrapper{

  }
  &.collapsed{
    border-left: 1px solid black;
    height: auto;
    .entry__title{
      margin-bottom: 0;
    }
    .close{
      opacity: 0;
    }
    .entry__content{
      display: none;
    }
  }
  &.featured{
    padding-top: 2rem;
    border-left: none;
    align-self: stretch;
    overflow: scroll;
    max-width: 100vw;
    .entry__wrapper{
      max-width: 50rem;
      margin: 0 auto;
    }
    .close{
      opacity: 1;
    }
    .entry__content{
      display: block;
    }
  }
  .entry__number{
    @include text--condensed(30px);
    &:after{
      content: "";
      display: inline-block;
      width: 2rem;
      height: 1px;
      background: black;
      transform: rotate(120deg);
      transform-origin: 50% 50%;
      position:relative;
      top: -.5rem;
      left: .25rem;
    }
  }
  .entry__artist{
    @include text--italic();
    font-size: 1.5rem;
  }
  .entry__number, .entry__artist{
    line-height: 2rem;
    vertical-align: baseline;
  }
  .entry__content{
    display: block;
  }
  .entry__links{
    margin-top: 1.5rem;
  }
  .entry__title{
    font-size: 2rem;
    a{
      color: black;
    }
    text-decoration: underline;
    text-decoration-skip: ink;
    cursor: pointer;
    text-decoration-color: black;
    text-decoration-style: solid;
    word-wrap: break-word;
    &:hover{
      color: black;
      text-decoration-style:dotted;
    }
  }
  .cta+.cta{
    margin-left: 1rem;
  }
  .close{
    position:absolute;
    top: 1.5rem;
    right: 1.5rem;
  }
}
