.grid-container{
  height: calc(100vh - 6rem);
  width: 100vw;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow: hidden;
}

.player-container{
  transition: height .3s linear;
  &.collapsed{
    height: 0;
  }
  &.open{
    height: auto;
    background: white;
    padding: 1.5rem 0;
    border-bottom: 1px solid black;
  }
  width: 100vw;
}

div.entries-container{
  width: 100vw;
  height: 50%;
  overflow: scroll;
  .entries-wrap{
    overflow: scroll;
    height: 100%;
    position:relative;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
  }
}

@media(min-width: 680px){
  div.entries-container{
    height: 100%;
  }
}
