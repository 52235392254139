@font-face {
  font-family: 'Bebas';
  src: url('../assets/bebas.woff') format('woff'), /* Modern Browsers */
    url('../assets/bebas.woff2') format('woff2'); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@mixin text--condensed($referenceSize) {
  font-family: 'Bebas',Helvetica,Arial,sans-serif;
  font-weight: normal;
  text-transform: uppercase;
  font-size: .76*($referenceSize);
  letter-spacing: 0;
}

@mixin text--body {
  font-family: 'Lora',Georgia,Times,serif;
  font-weight: 400;
}

@mixin text--italic {
  font-family: 'Lora',Georgia,Times,serif;
  font-weight: 400;
  font-style: italic;
}

@mixin text--header {
  font-family: 'Lora',Georgia,Times,serif;
  font-weight: 700;
  text-shadow: .0625rem .0625rem 0 #fff, -.0625rem .0625rem 0 #fff, .125rem 0 0 #fff, .125rem 0 0 #fff;
}

p{
  @include text--body;
  line-height: 1.5;
}

a.cta, button.cta, a.cta:hover, button.cta:hover, a.cta:visited, button.cta:visited{
  @include text--condensed(21px);
  color: $blue;
  text-decoration: none;
  font-weight: normal;
  vertical-align: baseline;
}

a, a:hover, a:visited{
  color: $blue;
  text-decoration: none;
}
