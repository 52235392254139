nav{
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  border-bottom: 1px solid black;
  padding: 0 1.5rem;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

.timeline-container{
  padding: 0 1.5rem;
  width: 100vw;
  height: 3rem;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  position:relative;

  .timeline-position-container{
    position:absolute;
    height: 100%;
    left: 1.5rem;
    right: 2rem;
    z-index: -1;
  }
  .timeline-position-indicator{
    position: absolute;
    width: .125rem;
    background: black;
    height: 100%;
    left: 100%;

  }

  a[data-entry-target]{
    position: relative;
    border-left-color: black;
    color: black;
    &:hover{
      border-left-color: $blue;
      color: $blue;
    }
  }

  .timeline-mark{
    position: relative;
    width: .5rem;
    height: .75rem;
    display: none;
    background: none;
    border-left: 1px solid;
    border-left-color: $blue;
    transition: all .2s ease;
    &[data-title]{
      display: block;
      color: $blue;
      border-left: 1px solid;
      border-left-color: $blue;
      height: 2rem;
    }
    &:after{
      content: attr(data-title);
      display: block;
      position: absolute;
      bottom: -0.125rem;
      left: .25rem;
      font-size: .75rem;
    }
    &:hover{
      border-left-color: $blue;
    }
  }
}



@media(min-width: 680px){
  .timeline-container .timeline-mark{
    display: block;
    &[data-title]{
      border-left: 2px solid $blue;
    }
  }
}
