header.site-header{
  text-align: center;
  padding: .5rem 1rem;
  position: relative;
  transition: height .4s ease;
  height: 3rem;
  .about-container{

    width: 100%;
    text-align: left;
    max-width: 30rem;
    margin: 0 auto;
    line-height: 1.5rem;
    opacity: 0;
    transition: opacity .4s .4s ease;
    padding-bottom: 3rem;

    #mc_embed_signup_scroll p, #mc_embed_signup_scroll input{
      display:inline-block;
    }
    #mc-embedded-subscribe{
      color: #06f;
      border: none;
      background: none;
      font-family: 'Lora',Georgia,Times,serif;
      cursor: pointer;
    }
    #mc-embedded-subscribe-form{
      margin-top: 2rem;
    }
    #mce-EMAIL{
      border: none;
      border-bottom: 1px solid #06f;
      font-family: 'Lora',Georgia,Times,serif;
    }
  }
  .logo-container{
    width: 100%;
    text-align:center;
    height: 2rem;
    position:relative;
    margin-bottom: 1rem;
    transition: all .2s ease;
    background: white;
    .logo--collapsed, .logo--open{
      position: absolute;
      width: 100%;
      height: 100%;
      background-size: contain;
      background-position: 50% 50%;
      background-repeat: no-repeat;
    }
    .logo--collapsed{
      opacity: 1;
    }
    .logo--open{
      opacity: 0;
    }
    &:hover{
      cursor:nesw-resize;
    }
  }
  &.open{
    min-height: 100vh;
    height: auto;

    .about-container{
      // position: absolute;
      // top: 50%;
      // left: 50%;
      // transform: translate(-50%, -50%);
      opacity: 1;
    }
    .logo-container{
      max-width: 100vw;
      height: 5rem;
      .logo--collapsed{
        opacity: 0;
      }
      .logo--open{
        opacity: 1;
      }
    }
  }
  overflow: hidden;
  border-bottom: 1px solid black;
}
